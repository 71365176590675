<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    TRANSAKSI BANK / <span style="font-weight: 600;">BANK GARANSI</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  HI,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          BANK GARANSI
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DATA BANK GARANSI
        </span>
      </div>
    </div>    
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">                           
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col v-if="formInput == add_bank_guarantee">
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >KODE BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.guarantee_id"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >NOMOR REKENING<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete
                          v-model="formInput.account_number"
                          id="account_number"
                          :items="data_bank_account_combobox"
                          @click="dataBankAccount_Combobox"
                          item-value="account_number"
                          item-text="account_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NILAI BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.total_amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NILAI TRANSFER<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.total_paid"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NILAI PENCAIRAN<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.total_received"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL DIBUAT<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_start"
                          v-model="date_start"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_published"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_published"
                            no-title
                            @input="date_start = false"
                            @change="onChangeDate(date_published)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL BERLAKU S/D<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="est_date_end"
                          v-model="est_date_end"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_end"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_end"
                            no-title
                            @input="est_date_end = false"
                            @change="onChangeDateEnd(date_end)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >ALAMAT BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="formInput.guarantee_address"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-else>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >KODE BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.guarantee_id"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important',
                            visibility: 'hidden',
                            position: 'absolute'
                          }"
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItemGuaranteeId.guarantee_id"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >NOMOR REKENING<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete
                          v-model="formInput.account_number"
                          id="account_number"
                          :items="data_bank_account_combobox"
                          @click="dataBankAccount_Combobox"
                          item-value="account_number"
                          item-text="account_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NILAI BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.total_amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NILAI TRANSFER<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.total_paid"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NILAI PENCAIRAN<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.total_received"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL DIBUAT<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_start"
                          v-model="date_start"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_published"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_published"
                            no-title
                            @input="date_start = false"
                            @change="onChangeDate(date_published)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL BERLAKU S/D<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="est_date_end"
                          v-model="est_date_end"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_end"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_end"
                            no-title
                            @input="est_date_end = false"
                            @change="onChangeDateEnd(date_end)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >ALAMAT BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="formInput.guarantee_address"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="close"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            color="blue"
            light
            class="mb-12"
            href="excel.php?mode=BG"
            target="_blank"            
            style="text-transform: capitalize !important; min-height: 48px;"              
          >
             Export Excel
          </v-btn>
          <v-spacer></v-spacer>          
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI BANK GARANSI"
            solo
            hide-details
            class="mb-12"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataBankGuarantee"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>        
        <v-data-table
          :headers="headers"
          :items="data_bank_guarantee"
          :item-key="guarantee_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.total_amount`]="{ item }">
            <td>Rp {{ formatNumber(item.total_amount) }}</td>
          </template>
          <template v-slot:[`item.total_paid`]="{ item }">
            <td>Rp {{ formatNumber(item.total_paid) }}</td>
          </template>
          <template v-slot:[`item.total_received`]="{ item }">
            <td>Rp {{ formatNumber(item.total_received) }}</td>
          </template>
          <template v-slot:[`item.guarantee_type`]="{ item }">
            <td v-if="item.guarantee_type === 'I'">
              INTERNAL
            </td>
            <td v-else-if="item.guarantee_type === 'EUK'">
              UANG KELUAR
            </td>
            <td v-if="item.guarantee_type === 'EUM'">
              UANG MASUK
            </td>
          </template>
          <template v-slot:[`item.aksi`]="{ item }">
            <div v-if="item.guarantee_type === 'EUK'">
              <div v-if="user_role.toLowerCase() === 'atasan keuangan'">
                <span
                  medium
                  style="color: black;"
                  v-if="item.show_notification == 0"
                >
                  BELUM SAATNYA CAIR
                </span>
                <v-btn
                  color="green"
                  light
                  medium
                  class="mr-3"
                  @click="generateReport(item)"
                  data-toggle="tooltip"
                  title="CAIR"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-else-if="
                    item.show_notification > 0 &&
                      item.status_cashing === 0 &&
                      item.upload_status === 1
                  "
                >
                  <span medium style="color: #ffffff;">
                    CAIR
                  </span>
                </v-btn>
                <span medium style="color: black;" v-else>
                  SUDAH CAIR
                </span>
              </div>
              <v-icon small color="black" v-else>
                flaticon2-line
              </v-icon>
            </div>
            <div v-if="item.guarantee_type === 'EUM'">
              <div v-if="user_role.toLowerCase() === 'atasan keuangan'">
                <span
                  medium
                  style="color: black;"
                  v-if="item.show_notification == 0"
                >
                  BELUM SAATNYA CAIR
                </span>
                <v-btn
                  color="green"
                  light
                  medium
                  class="mr-3"
                  @click="updateDataStatusCashing(item)"
                  data-toggle="tooltip"
                  title="CAIR"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-else-if="
                    item.show_notification > 0 &&
                      item.status_cashing === 0 &&
                      item.upload_status === 1
                  "
                >
                  <span medium style="color: #ffffff;">
                    CAIR
                  </span>
                </v-btn>
                <span medium style="color: black;" v-else>
                  SUDAH CAIR
                </span>
              </div>
              <v-icon small color="black" v-else>
                flaticon2-line
              </v-icon>
            </div>
            <div v-else-if="item.guarantee_type === 'I'">
              <div v-if="user_role.toLowerCase() === 'atasan keuangan'">
                <span
                  medium
                  style="color: black;"
                  v-if="item.show_notification == 0"
                >
                  BELUM SAATNYA CAIR
                </span>
                <v-btn
                  color="green"
                  light
                  medium
                  class="mr-3"
                  @click="updateDataStatusCashing(item)"
                  data-toggle="tooltip"
                  title="CAIR"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-else-if="
                    item.show_notification > 0 &&
                      item.status_cashing === 0 &&
                      item.upload_status === 1
                  "
                >
                  <span medium style="color: #ffffff;">
                    CAIR
                  </span>
                </v-btn>
                <span medium style="color: black;" v-else>
                  SUDAH CAIR
                </span>
              </div>
              <v-icon small color="black" v-else>
                flaticon2-line
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </template>
      <!-- PDF Session -->
      <template>
        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :pdf-quality="2"
            :manual-pagination="false"
            :html-to-pdf-options="htmlToPdfOptions"
            pdf-content-width="100%"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <!-- PDF Content Here -->
              <section class="pdf-item">
                <div align="center">
                  <table
                    border="1"
                    cellpadding="3"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr align="center">
                        <th
                          scope="col"
                          width="60px"
                          style="font-style: normal; padding: 0 5px 0 5px; font-size: 16px;"
                        >
                          BANK
                        </th>
                        <th
                          scope="col"
                          width="60px"
                          style="font-style: normal; padding: 0 5px 0 5px; font-size: 16px;"
                        >
                          NO REKENING
                        </th>
                        <th
                          scope="col"
                          width="60px"
                          style="font-style: normal; padding: 0 5px 0 5px; font-size: 16px;"
                        >
                          PERUSAHAAN
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      :items="data_bank_guarantee_cetak"
                      :item-key="guarantee_id"
                    >
                      <tr>
                        <td
                          style="font-style: normal; padding: 0 5px 0 5px; font-size: 12px;"
                        >
                          {{ data_bank_guarantee_cetak.bank_name }}
                        </td>
                        <td
                          style="font-style: normal; padding: 0 5px 0 5px; font-size: 12px;"
                        >
                          {{ data_bank_guarantee_cetak.account_number }}
                        </td>
                        <td
                          style="font-style: normal; padding: 0 5px 0 5px; font-size: 12px;"
                        >
                          {{ data_bank_guarantee_cetak.workgroup_name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <!--ADD_PAGE-->
              <div class="html2pdf__page-break" />
            </section>
          </vue-html2pdf>
        </div>
      </template>
      <!-- END PDF Session -->
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import VueHtml2pdf from "vue-html2pdf";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";

export default {
  name: "bank-guarantee",

  components: {
    ApprovalNotification,
    VueHtml2pdf
  },

  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    create_data_bank_guarantee: [],
    create_data_voucher: [],
    update_data_bank_guarantee: [],
    delete_data_bank_guarantee: [],
    data_bank_guarantee: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_project_combobox: [],
    data_bank_account_combobox: [],
    data_bank_guarantee_date: {
      date_published: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    add_bank_guarantee: {
      guarantee_id: "",
      project_id: "",
      guarantee_address: "",
      account_number: "",
      total_amount: 0,
      total_paid: 0,
      total_received: 0
    },
    add_bank_guarantee_not_null: {
      date_start: false,
      est_date_end: false,
      date_published: formatDate(),
      date_end: formatDate()
    },
    data_bank_guarantee_cetak: {
      account_number: ""
    },
    submitted: false,
    headers: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    editedItemGuaranteeId: {},
    deletedItem: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    htmlToPdfOptions: {
      margin: 0.1,
      filename: `Surat Rekening.pdf`,
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait"
      }
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    date_published: "",
    date_end: "",
    date: "",
    user_role: "",
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    loadingTable: true
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      return this.editedIndex === -1
        ? "Buat Bank Garansi"
        : "Ubah Bank Garansi";
    },

    formInput() {
      return this.editedIndex === -1
        ? this.add_bank_guarantee
        : this.editedItem;
    },

    formInputNotClear() {
      return this.editedIndex === -1
        ? this.add_bank_guarantee_not_null
        : this.editedItem;
    },

    formSubmit() {
      return this.editedIndex === -1
        ? this.createDataBankGuarantee
        : this.updateDataBankGuarantee;
    },

    computedDate() {
      return this.formatDate(this.date_published);
    },

    computedDateEnd() {
      return this.formatDate(this.date_end);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    date_published() {
      this.formInputNotClear.date_published = this.formatDate(
        this.date_published
      );
    },

    date_end() {
      this.formInputNotClear.date_end = this.formatDate(this.date_end);
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    this.initHeders();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bank Garansi" }]);
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "guarantee_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "NO BANK GARANSI",
          sortable: true,
          value: "guarantee_id",
          width: "190px"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name",
          width: "150px"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name",
          width: "100px"
        },
        {
          text: "BANK",
          sortable: true,
          value: "bank_name",
          width: "100px"
        },
        {
          text: "NO REKENING",
          sortable: true,
          value: "account_number",
          width: "150px"
        },
        {
          text: "NILAI BANK GARANSI",
          sortable: true,
          value: "total_amount",
          width: "200px"
        },
        {
          text: "NILAI TRANSFER",
          sortable: true,
          value: "total_paid",
          width: "170px"
        },
        {
          text: "NILAI PENCAIRAN",
          sortable: true,
          value: "total_received",
          width: "180px"
        },
        {
          text: "TGL TERBIT",
          sortable: true,
          value: "date_published",
          width: "140px"
        },
        {
          text: "TGL BERLAKU S/D",
          sortable: true,
          value: "date_end",
          width: "180px"
        },
        {
          text: "TIPE",
          sortable: true,
          value: "guarantee_type",
          width: "100px"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false,
          width: "100px"
        }
      ];
    },

    loadDate: function() {
      this.date_published = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_published = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateEnd: function() {
      this.date_end = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_end = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_start.save(item);
      this.data_bank_guarantee_date.date_published = item;
    },

    onChangeDateEnd(item) {
      this.$refs.est_date_end.save(item);
      this.data_bank_guarantee_date.date_end = item;
    },

    createDataBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          guarantee_id: this.add_bank_guarantee.guarantee_id,
          project_id: this.add_bank_guarantee.project_id,
          guarantee_address: this.add_bank_guarantee.guarantee_address,
          account_number: this.add_bank_guarantee.account_number,
          total_amount: this.add_bank_guarantee.total_amount,
          total_paid: this.add_bank_guarantee.total_paid,
          total_received: this.add_bank_guarantee.total_received,
          date_published: this.data_bank_guarantee_date.date_published,
          date_end: this.data_bank_guarantee_date.date_end,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.create_data_bank_guarantee = response.data;
            this.submitted = true;
            this.save("add_bank_guarantee");
            this.loadDataBankGuarantee();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataVoucherBankIn(item) {
      return new Promise(resolve => {
        let uploadStatus = 0;

        if (item.guarantee_type == "I") {
          uploadStatus = 1;
        } else if (
          item.guarantee_type == "EUK" ||
          item.guarantee_type == "EUM"
        ) {
          uploadStatus = 0;
        } else {
          uploadStatus = 2;
        }

        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MUTATION",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          tax_invoice_number: "",
          invoice_number: "",
          street_mail_number: "",
          bank_transaction_desc: "",
          bank_transaction_date: this.data_bank_guarantee_date.date,
          bank_transaction_permission: "BM",
          amount: item.total_amount,
          path_image_evidence_of_transfer:
            "http://emitra.asiatechintiraya.id/images/evidence-of-transfer/.",
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: uploadStatus,
          status: item.guarantee_type == "EUM" ? 2 : 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            this.create_data_voucher = response.data;
            this.submitted = true;
            this.loadDataBankGuaranteeNotification();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataVoucherBankOut(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MUTATION",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          bank_transaction_desc: "dari bank garansi",
          bank_transaction_date: this.data_bank_guarantee_date.date,
          bank_transaction_type: item.guarantee_type,
          bank_transaction_permission: "BK",
          transaction_type: "U",
          reference: "",
          amount: item.total_amount,
          path_image_transaction_type: "",
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 0,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            this.create_data_voucher = response.data;
            this.submitted = true;
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          guarantee_id: this.editedItemGuaranteeId.guarantee_id,
          project_id: this.editedItem.project_id,
          guarantee_address: this.editedItem.guarantee_address,
          account_number: this.editedItem.account_number,
          total_amount: this.editedItem.total_amount,
          total_paid: this.editedItem.total_paid,
          total_received: this.editedItem.total_received,
          date_published: this.data_bank_guarantee_date.date_published,
          date_end: this.data_bank_guarantee_date.date_end,
          modified_by: localStorage.getLocalStorage("User_Fullname"),
          date_modified: this.data_bank_guarantee_date.date,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_bank_guarantee = response.data;
            this.submitted = true;
            this.save("edit_bank_guarantee");
            this.loadDataBankGuarantee();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataStatusCashing(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "STATUS_CASHING",
          guarantee_id: item.guarantee_id,
          total_paid: item.total_amount,
          total_received: item.total_amount,
          status_cashing: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_bank_guarantee = response.data;
            this.submitted = true;
            this.save("edit_bank_guarantee");
            this.asyncCreateDataVoucherBankIn(item);
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          guarantee_id: this.deletedItem.guarantee_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_bank_guarantee = response.data;
            this.submitted = true;
            this.data_bank_guarantee.splice(this.deletedIndex, 1);
            this.closeDelete();
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee = response.data;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataProject_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Project",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_project_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankAccount_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/BankAccount",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_account_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    generateReport(item) {
      this.updateDataStatusCashing(item);
      this.data_bank_guarantee_cetak = Object.assign({}, item);
      this.$refs.html2Pdf.generatePdf();
    },

    async load() {
      Promise.all([
        // await this.dataEscrowPaymentAccepted(),
        // await this.dataBankGuaranteeNotification(),
        // await this.dataSubmissionFormNotification(),
        await this.dataBankGuarantee()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataBankGuarantee() {
      Promise.all([await this.dataBankGuarantee()]).then(function(results) {
        results;
      });
    },

    async loadDataBankGuaranteeNotification() {
      Promise.all([
        await this.dataBankGuaranteeNotification(),
        await this.dataBankGuarantee()
      ]).then(function(results) {
        results;
      });
    },

    async loadCombobox() {
      Promise.all([
        await this.dataProject_Combobox(),
        await this.dataBankAccount_Combobox()
      ]).then(function(results) {
        results;
      });
    },

    async asyncCreateDataVoucherBankIn(item) {
      Promise.all([await this.createDataVoucherBankIn(item)]).then(function(
        results
      ) {
        results;
      });
    },

    editItem(item) {
      this.editedIndex = this.data_bank_guarantee.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItemGuaranteeId = Object.assign({}, item);
      this.loadCombobox();
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_bank_guarantee.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data_bank_guarantee.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_bank_guarantee.guarantee_id = "";
        this.add_bank_guarantee.project_id = "";
        this.add_bank_guarantee.guarantee_address = "";
        this.add_bank_guarantee.account_number = "";
        this.add_bank_guarantee.total_amount = "";
        this.add_bank_guarantee.total_paid = "";
        this.add_bank_guarantee.total_received = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_bank_guarantee") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_bank_guarantee[this.editedIndex],
            this.add_bank_guarantee
          );
        } else {
          this.data_bank_guarantee.push(this.add_bank_guarantee);
        }
      } else if (formInput == "edit_bank_guarantee") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_bank_guarantee[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_bank_guarantee.push(this.editedItem);
        }
      }
      this.close();
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  }
};
</script>
